* {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
}
body { 
  margin: 0;
  /* pointer-events: none */
}
#root {
  width: 100%;
  margin: auto;
  /* margin-top: 200px; */
  margin-top: 150px;
}
/* Стили текста, параграфов:  */
#root p{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.data{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.MsoBodyText{
  text-align: center;
}
.MsoBodyTextIndent{
  text-align:justify;
  margin: auto;
  text-indent: 2em; 
}
#MsoBodyTextIndent{
  display: inline-block !important;
  text-align:justify;
  text-indent: 2em; 
}
.MsoNormal{
  display: inline-block !important;
  text-align:justify;
  text-indent: 2em; 
}
.BodyText2{
  margin: auto;
}
.red{color: red;}
.blue{color: blue;}
.green{color: green;}
.parentContainerrr .green {
  color: inherit !important;
} 
/* Стили некликабельности + cтили кнопки с изображением word: */
.content-wrapper {
  margin-right: 20%; 
  margin-left: 20%;
  padding: 20px;
  position: relative;
  /* pointer-events: none; */
}
.content {
  pointer-events: none; 
  user-select: none;
}
.clickable-button {
  /* position: relative; */
  position: fixed;
  bottom: 0px;
  left: 0px;
  /* bottom: 10px;
  left: 10px; */
  z-index: 10;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px 18px; */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 10px 0; /* Уберите горизонтальное padding или задайте его значение */
  width: 20vw; /* Устанавливаем ширину как 20% от ширины экрана */
  box-sizing: border-box; /* Убедитесь, что padding не влияет на ширину */
}
.clickable-button:hover {
  background-color: #0056b3;
  transform: scale(1.02);
}
.wordImg {
  margin-right: 10px;
  width: 44px;
  height: 44px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 5;
}
/* Цвет ссылок: */
.linkk{
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s ease;
}
.linkk:hover {
  color: purple;
}
.popup{
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  background-color: #007bff;
  color: white;
  border: none; 
}
.payButton{
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.payButton button{
  display: block;
  margin: auto;
  font-size: 20px;
  padding: 15px 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* background-color: rgb(3, 92, 3); */
  background-color: rgb(25, 37, 199);
  color: white;
  border: none;
  border-radius: 5px;
}
.payButton button:hover{
  background-color: purple;
  transform: scale(1.2);
}
.popupTitle{
  width: 90%;
}
.popupClose{
  cursor: pointer;
  position: fixed;
  top: 150px;
  right: 10px;
  font-size: 30px;
}
.finishPay{
  width: 80%;
  margin: auto;
  margin-top: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}
.finishPay h2{
  color:#0056b3;
  text-align: center;
  width: 50%;
}

.finishPay img{
  width: 30%;
  border: 1px solid black;
}
.footer{
  /* position: fixed;
  width: 90%;
  margin: auto; */
  /* margin-bottom: 0; */
  color: #34495e;
  font-size: 24px;
  font-style: italic;
}
.footerImg{
  display: block;
  width: 50px;
  margin: auto;
  margin-top: 40px;
  border-radius: 20px
}
.h1Home {
  margin-top: 200px;
  color: #34495e;
  text-align: center;
  font-size: 40px;
}

/* Полоса в header: */
.line {
  white-space: nowrap; /* Предотвращает перенос */
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1332px) {
  .line {
    font-size: 0.9em; /* Уменьшает размер текста при уменьшении экрана */
  }
}
