.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-color: #ffffff;
    margin-top: 30px;
    margin-bottom: 20px;
}

.commun {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    background-color: #e0f7fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    margin-top: 60px;
}

.exemp {
    margin: 10px;
    width: 100%;
}

.exemp select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.buttonForm {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
}

.buttonForm button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.buttonForm button:hover {
    transform: scale(1.1); 
}

.customDropdown {
    position: relative;
}

.selectedOption {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: white; 
    cursor: pointer;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    font-size: 13px;
    height: 40px;
    margin-top: 6px;
}

.selectedOption::after {
    content: '\276E';
    font-size: 12px;
    color: #000000;
    transform: rotate(270deg);
    font-weight: 900;
}

.optionsList {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 10;
}

.option, .subOption {
    padding: 10px;
    cursor: pointer;
}

.option:hover, .subOption:hover {
    background: #f0f0f0;
}

.selectedOption.invalid {
    background-color: #ffdddd; 
    border-color: #ff0000;
}

.exemp select.invalid {
    background-color: #ffcccc; 
    border-color: #ff0000;
}

@media (min-width: 768px) {
    .commun {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .buttonForm {
        grid-column: 2;
    }
}

/* .commun {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
    background-color: #e0f7fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    margin-top: 60px;
}

.exemp {
    margin: 10px;
    width: 100%;
}

.exemp select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.exemp select.invalid {
    background-color: #ffcccc; 
}

.buttonForm {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
}

.buttonForm button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}
.buttonForm button:hover {
    transform: scale(1.1);
}

@media (min-width: 768px) {
    .commun {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .buttonForm {
        grid-column: 2;
    }
} */