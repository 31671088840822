.container {
    background-color: #e0f7fa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -150px;
}

.logo {
    width: 60%;
    max-width: 768px;
    display: block;
    margin: 0 auto;
    border-radius: 20px;
    margin-top: 20px;
}

.h1 {
    font-size: 28px;
    text-align: center;
    color: #333;
    padding: 10px 20px;
}

.h2 {
    font-size: 18px;
    text-align: center;
    color: #0073e6;
    margin: 20px 0;
}

.img {
    display: block;
    margin: auto;
    width: 100%;
}

/* Стили для мобильных устройств */
@media (max-width: 768px) {
    .h1 {
        font-size: 24px;
    }

    .h2 {
        font-size: 16px;
    }
}
